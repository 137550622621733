<template>

  <div class="px-4">
    <ErrorPopup 
      :error="error_popup_message"
    />
    <InvoiceCreateOptionsModal
      ref="invoiceOptionsModal"
      :count="totalRows"
      @on_confirmed_generate_invoices="on_confirmed_generate_invoices"
      @onCancelled="onCancelInvoiceOptions"
    />
    <ExportOptionsModal
      ref="exportOptionsModal"
      @save="onConfirmExport"
      @cancel="onCancelExport"
    />

    <Confirm
      :title="$t('MEMBER_RENEW.CONFIRM')"
      :message="$t('MEMBER_RENEW.ARE_YOU_SURE_DELETE')"
      ref="deleteRenewalConfirm"
      type="success"
      @confirm="onDeleteRenewalConfirm"
    />

    <SelectRenewsForBatch
      ref="selectRenewsForBatchModalEmail"
      type="email"
      :options="calc_options"
      :selected_renew_ids="selected_renew_ids"
    />

    <SelectRenewsForBatch
      ref="selectRenewsForBatchModalSMS"
      type="sms"
      :options="calc_options"
      :selected_renew_ids="selected_renew_ids"
    />

    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('PAGES.RENEWS.CONFIRM_SMS')"
      ref="createSMSBatchConfirm"
      type="success"
      @confirm="onCreateSMSBatchConfirmed"
    />

    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('PAGES.RENEWS.CONFIRM_EMAIL')"
      ref="createEmailBatchConfirm"
      type="success"
      @confirm="onCreateEmailBatchConfirmed"
    />

    <MemberEditorCompactModal
      ref="member-editor-modal"
      :member_id="member_id"
      @updated="member_updated"

    />

    <div class="d-flex justify-content-end mt-8">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-6"
        @click="create_email_batch_clicked"
        ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('PAGES.RENEWS.EMAIL_JOB')}}</a
      >
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-6"
        @click="create_sms_batch_clicked"
        ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('PAGES.RENEWS.SMS_JOB')}}</a
      >
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-6"
        @click="exportXlsxClicked"
        ><i class="menu-icon flaticon2-file" style="font-size: 1.0em;"></i>{{$t('COMMON.EXPORT_XLSX')}}</a
      >
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-6"
        @click="openConfirmInvoiceOptions"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.RENEWS.CREATE_INVOICES')}}</a
      >

    </div>

    <b-row class="mt-6">
      <b-col cols="12" sm="12" lg="6">
        <b-form-input
            id="filter-input"
            v-model="filters.text"
            type="search"
            :placeholder="$t('COMMON.SEARCH')"
          ></b-form-input>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="3" sm="12">
        <b-form-group
          id="input-group-created_from"
          :label="$t('PAGES.RENEWS.CREATED_FROM')"
          label-for="input-created_from"
        >
          <memlist-date-picker v-model="filters.created_from"></memlist-date-picker>
        </b-form-group>

      </b-col>
      <b-col lg="3" sm="12">
        <b-form-group
          id="input-group-created_to"
          :label="$t('PAGES.RENEWS.CREATED_TO')"
          label-for="input-created_to"
        >
          <memlist-date-picker v-model="filters.created_to"></memlist-date-picker>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="3" sm="12">
        
        <b-form-group
          :label="$t('MEMBER_RENEW.STATUS')">

          <Multiselect
            class="ml-2 multiselect-blue"
            v-model="filters.statuses"
            ref="statuses_multiselect"
            mode="tags"
            :placeholder="$t('COMMON.ALL')"
            :close-on-select="false"
            :filter-results="false"
            :options="statuses"
          />

        </b-form-group>
      </b-col>

      <b-col lg="3" sm="12">
        
        <b-form-group
          :label="$t('MEMBER_RENEW.PAYMENT_STATUS')">

          <Multiselect
            class="ml-2 multiselect-blue"
            v-model="filters.payment_statuses"
            ref="payment_statuses_multiselect"
            mode="tags"
            :placeholder="$t('COMMON.ALL')"
            :close-on-select="false"
            :filter-results="false"
            :options="paymentStatuses"
          />

        </b-form-group>
      </b-col>
      <b-col lg="3" sm="12">
        <b-form-group
          id="email_option"
          :label="$t('MEMBER.EMAIL')"
          label-for="email_option"

        >
          <b-form-select
            v-model="filters.email_option"
            :options="emailOptionOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

    </b-row>


    <b-row>
      <b-col cols="6" >
        <div class="bottom-alignment">
          <strong>{{ $t('COMMON.NUM_ROWS') }}:</strong> {{totalRows}}
        </div>

      </b-col>
      <b-col cols="6">
        <div class="d-sm-flex justify-content-end align-items-right align-bottom w-100 mb-2">

          <b-form-select
            class="mr-sm-2 mt-2 align-bottom"
            style="max-width: 100px;"
            :options="[100, 250, 500]"
            v-model="per_page"
          />
          <b-pagination
            class="mb-0 mt-2 align-bottom"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="per_page"
            aria-controls="renew-table"
            first-number
            last-number
          ></b-pagination>
        </div>
      </b-col>
    </b-row>

    <b-table
      id="renew-table"

      :items="ajaxPagination"
      :fields="headers"
      :filter="filters"
      :filter-function="customFilter"
      :per-page="per_page"
      :current-page="currentPage"
      :tbody-tr-class="rowClass"
      head-variant="light"
      ref="renewTable"
      no-select-on-click
      @filtered="onFiltered"
      @sort-changed="onSortChanged"
    >
      <template #cell(status)="row">
        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'SCHEDULED'"
          color="gray"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'ACTIVE'"
          color="orange"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'DELETED'"
          color="red"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'DONE'"
          color="green"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>


        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'TIME_OUT'"
          color="red"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'AWAITING_PAYMENT'"
          color="orange"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'STARTED_LINK'"
          color="orange"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.status === 'INVOICED'"
          color="blue"
          outlined
        >
          {{ getStatus(row.item.status) }}
        </v-chip>

      </template>


      <template #cell(payment_status)="row">
        <v-chip
          style="font-size: 11px;"
          v-if="row.item.payment_status === 'LATE'"
          color="red"
          outlined
        >
          {{ getPaymentStatus(row.item.payment_status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.payment_status === 'PAID'"
          color="green"
          outlined
        >
          {{ getPaymentStatus(row.item.payment_status) }}
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="row.item.payment_status === 'WAIT'"
          color="blue"
          outlined
        >
          {{ getPaymentStatus(row.item.payment_status) }}
        </v-chip>
      </template>

      <template #cell(next_shop_item_ok)="row">
        <v-chip
          style="font-size: 11px;"
          v-if="row.item.next_shop_item_ok"
          color="green"
          outlined
        >
          OK
        </v-chip>

        <v-chip
          style="font-size: 11px;"
          v-if="!row.item.next_shop_item_ok"
          color="red"
          outlined
        >
          Saknas
        </v-chip>

      </template>

      <template #cell(show_details)="row">
        <div class="d-flex justify-content-end">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click="gotoMember(row.item)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-user color-primary"></i>
            </span>
          </a>
          <a class="btn btn-icon btn-light btn-sm mx-2" @click="copyLink(row)">
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-link color-primary"></i>
            </span>
          </a>
          <a class="btn btn-icon btn-light btn-sm mx-2" @click="row.toggleDetails">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
          <a class="btn btn-icon btn-light btn-sm mx-2" @click="deleteRenewalClicked(row)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
      <template #row-details="row">
        <b-card style="cursor: default;" class="display-flex">
          <b-card-body>

            <p class="error-text" v-if="row.item.member.member_companies === null || row.item.member.member_companies.length === 0">
              {{ $t('MEMBER_RENEW.INVOICE_ERROR1') }}
            </p>

            <div
              v-for="(member_company, index) in row.item.member.member_companies"
              :key="index"
              class=""
            >
              <b-row class="mb-2">
                <b-col sm="2"><b>{{$t('MEMBER.MEMBERSHIP')}}:</b></b-col>
                <b-col>{{ member_company.company.name }} - {{ member_company.shop_item ? member_company.shop_item.name : 'Okänt' }}</b-col>
              </b-row>

              <p class="error-text" v-if="member_company.shop_item === null">{{ $t('MEMBER_RENEW.INVOICE_ERROR2') }}</p>
              <p class="error-text" v-if="member_company.shop_item !== null && member_company.shop_item.next_shop_item === null">{{ $t('MEMBER_RENEW.INVOICE_ERROR3') }}</p>

              <b-row class="mb-6">

                <b-col
                  v-if="member_company.shop_item && member_company.shop_item.next_shop_item"
                  sm="2"
                  ><b>{{$t('PAGES.RENEWS.RENEW_INTO')}}:</b></b-col
                >
                <b-col
                  v-if="member_company.shop_item && member_company.shop_item.next_shop_item"
                  sm="4"
                  >{{ member_company.shop_item.next_shop_item.name }}</b-col
                >

              </b-row>

              <hr />
            </div>
            <b-button size="sm" @click="row.toggleDetails">{{$t('COMMON.HIDE')}}</b-button>
          </b-card-body>
        </b-card>
      </template>
    </b-table>

    <div v-if="is_table_loading" class="d-flex justify-content-center mt-3" >
        <memlist-spinner />
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import InvoiceCreateOptionsModal from '@/view/components/InvoiceCreateOptionsModal.vue';
import ExportOptionsModal from '@/view/components/ExportOptionsModal.vue';
import SelectRenewsForBatch from '@/view/pages/ml/renew/SelectRenewsForBatch.vue';
import swal from 'sweetalert2';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import ErrorPopup from '@/view/components/ErrorPopup.vue';


export default {
  name: 'RenewsTable',
  props: [],
  components: {
    ErrorPopup,
    Multiselect,
    InvoiceCreateOptionsModal,
    ExportOptionsModal,
    SelectRenewsForBatch,
  },
  mixins: [ toasts ],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods', 'companies', 'sid']),
    calc_options() {
      return this.getOptions()
    } 
  },
  watch: {

    currentPeriodId: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.set_period_filter();
      },
      immediate: true
    },
  },
  mounted() {
    this.renewals = [];

    this.getPageCount();
  },
  methods: {

    async load_renew_settings_data() {
      const res = await axios.get(`/company/admin?company_id=${this.currentCompanyId}`);

      if (res.status === 200) {
        return res.data.renew;
      }
    },

    set_period_filter() {
      if (this.periods.length === 1) {
        this.filters.created_from = dayjs().subtract(365, 'days').format('YYYY-MM-DD');
        this.filters.created_to = dayjs().format('YYYY-MM-DD');
      }
      else {
        const period = this.periods.find(item => item.id === this.currentPeriodId);
        
        if (period) {
          this.filters.created_from = dayjs(period.from).format('YYYY-MM-DD');
          this.filters.created_to = dayjs(period.to).format('YYYY-MM-DD');
        }
        else {
          
          setTimeout(() => {
            this.set_period_filter();
          }, 1000);

          return;
        }
      }

      if (this.$refs['renewTable']) {
        this.$refs['renewTable'].refresh();
      }
      
    },

    member_updated() {
      if (this.$refs['renewTable']) {
        this.$refs['renewTable'].refresh();
      }
    },

    member_selected(member_id) {
      this.member_id = member_id;
      this.$refs['member-editor-modal'].show(member_id);
    },

    isRenewalSelected(renewal, member_company) {

      return this.createRenewals[renewal.id];
    },

    onSortChanged(sortChanged) {

      if (sortChanged.sortBy === '') {
        this.orderBy = undefined;
      }
      else {
        this.orderBy = {

        };

        this.orderBy[sortChanged.sortBy] = sortChanged.sortDesc ? 'desc' : 'asc';
      }
    },

    getOptions() {

      const options = {
        period_id: this.currentPeriodId
      };

      if (this.filters.created_from) {
        options.created_from = this.filters.created_from;
      }

      if (this.filters.created_to) {
        options.created_to = this.filters.created_to;
      }

      if (this.filters.email_option) {
        options.email_option = this.filters.email_option;
      }

      if (this.filters.statuses) {
        options.statuses = this.filters.statuses;
      }
      else {
        options.statuses = [];
      }


      if (this.filters.payment_statuses) {
        options.payment_statuses = this.filters.payment_statuses;
      }
      else {
        options.payment_statuses = [];
      }

      if (this.filters.text !== '') {
        options.text = this.filters.text;
      }

      return options;
    },

    getPageCount() {

      const options = this.getOptions();

      axios
        .post(`/member_renew/count/${this.currentPeriodId}?limit=${this.per_page}&page=${this.currentPage}`, { options: options })
        .then(res => {
          this.totalRows = res.data.count;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_COUNT'));
        });
    },
    exportXlsxClicked() {
      this.$refs['exportOptionsModal'].show();
    },
    onConfirmExport() {
      this.$refs['exportOptionsModal'].hide();
    },
    onCancelExport() {
      this.$refs['exportOptionsModal'].hide();
    },

    openConfirmInvoiceOptions() {

      if (this.has_errors) {
        this.error_popup_message = 'ERR_RENEWS_HAVE_ERRORS';

        this.$nextTick(()=>{ this.error_popup_message = null; });
        return;
      }
      
      this.$refs['invoiceOptionsModal'].show();
    },

    on_confirmed_generate_invoices(due_date) {

      this.due_date = due_date;

      this.generateInvoicesFromFilter();
    },

    generateInvoicesFromFilter() {

      const loader = this.$loading.show();

      axios
        .post('/invoice/generate_invoices_for_renewals', {
          option: 'FILTER',
          options: this.getOptions(),
          due_date: this.due_date
        })
        .then(res => {
          loader && loader.hide();

          if (res.status === 201) {
            swal.fire(this.$t('PAGES.RENEWS.INVOICE_JOB_CREATED'), '', 'success');

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.RENEWS.INVOICES_GENERATED'));
            this.$refs['renewTable'].refresh();
            this.$refs['invoiceOptionsModal'].close();
          } else if (res.status === 201 && res.data.length === 0) {
            this.toastr('warning', this.$t('COMMON.OK'), this.$t('PAGES.RENEWS.NO_INVOICES_GENERATED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_GENERATE_INVOICES'));
          }
        })
        .catch(err => {
          loader && loader.hide();

          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_GENERATE_INVOICES'));
        });
    },


    onCancelInvoiceOptions() {
    },

    ajaxPagination: function(ctx, callback) {
      const vm = this;
      let params = {
        pageNo: ctx.currentPage,
        pageSize: ctx.per_page,
        flag: 0,
        po: vm.searchOrderNumber,
        styNum: vm.searchStyleNumber
      };

      callback([]);
      this.is_table_loading = true;

      this.getPageCount();

      const options = this.getOptions();

      axios
        .post(`/member_renew/list/${this.currentPeriodId}?page=${this.currentPage}&limit=${this.per_page}`, {
          options,
          orderBy: this.orderBy
        })
        .then(res => {
          this.is_table_loading = false;
          this.has_errors = !!res.data.find(item => item.error !== 'NONE')
          var arr = res.data;
          callback(arr || []);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
          console.error('pagination get error', err);
        });


      return null;
    },

    gotoMember(renewal) {
      this.member_selected(renewal.member_id);
    },
    getNextShopItemStatus(next_shop_item_ok) {
      return next_shop_item_ok ? this.$t('COMMON.OK') : this.$t('COMMON.MISSING');
    },
    getCurrentShopItemStatus(current_shop_item_ok) {
      return current_shop_item_ok ? this.$t('COMMON.OK') : this.$t('COMMON.MISSING');
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return item.error === 'NONE'
        ? (item.status === 'DONE' ? 'table-success' : 'table-warning' )
        : 'table-danger';
    },
    getStatus(status) {
      return {
        SCHEDULED: this.$t('PAGES.RENEWS.SCHEDULED'),
        ACTIVE: this.$t('PAGES.RENEWS.ACTIVE'),
        STARTED_LINK: this.$t('PAGES.RENEWS.STARTED_LINK'),
        INVOICED: this.$t('PAGES.RENEWS.INVOICED'),
        DELETED: this.$t('PAGES.RENEWS.DELETED'),
        DONE: this.$t('PAGES.RENEWS.DONE'),
        AWAITING_PAYMENT: this.$t('PAGES.RENEWS.AWAITING_PAYMENT'),
        TIME_OUT: this.$t('PAGES.RENEWS.TIME_OUT')
      }[status];
    },
    getPaymentStatus(status) {
      return {
        LATE: this.$t('SHOP_ORDER.LATE'),
        PAID: this.$t('SHOP_ORDER.PAID'),
        WAIT: this.$t('SHOP_ORDER.WAIT')
      }[status];
    },
    getCompanyName(id) {
      return this.companies.find(company => company.id === id).name;
    },
    getCount(type, item) {
      const count = item.sendouts.filter(sendout => sendout.method === type);

      return count.length;
    },
    copyLink(row) {
      navigator.clipboard
        .writeText(
          `${window.location.origin}/renew-wizard/${this.sid}/${row.item.member_id}/${row.item.token}`
        )
        .then(() => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SHOP_ORDER.CLIPBOARD_COPY'));
        })
        .catch(() => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SHOP_ORDER.UNABLE_CLIPBOARD_COPY'));
        });
    },


    onDeleteRenewalConfirm() {
      if (this.toDelete === null) {
        return;
      } else if (this.toDelete === 0) {
        this.deleteRenewals();
      } else {
        this.deleteRenewal(this.toDelete);
      }

      this.toDelete = null;
    },
    deleteRenewalClicked(row) {
      this.toDelete = row.item.id;
      this.$refs['deleteRenewalConfirm'].show();
    },
    deleteRenewalsClicked() {
      this.toDelete = 0;
      this.$refs['deleteRenewalConfirm'].show();
    },

    postBatchSMS(renew_ids) {
      axios
        .post('/member_renew/batch/sms', { renew_ids })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.JOBS.CREATED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_CREATE'));
          }
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_CREATE'));
          console.error(err);
        });
    },


    postBatchEmail(renew_ids) {
      axios
        .post('/member_renew/batch/email', { renew_ids })
        .then(res => {
          if (res.status === 201) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.JOBS.CREATED'));
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_CREATE'));
          }
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_CREATE'));
          console.error(err);
        });
    },


    deleteRenewal(id) {
      axios
        .delete(`/member_renew/${id}`)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.RENEWS.DELETED'));
            this.$refs['renewTable'].refresh();
          }
          else if (res.status === 409) {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_DELETE') + '(E1)');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_DELETE') + '(E2)');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_DELETE') + '(E3)');
        });
    },

    async create_email_batch_clicked() {

      try {
        const renew_settings = await this.load_renew_settings_data();

        if (!renew_settings.send_email) {
          this.error_popup_message = 'ERR_EMAIL_RENEW_NOT_ACTIVE';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          return;
        }

        if (!renew_settings.email_template_id) {
          this.error_popup_message = 'ERR_EMAIL_RENEW_TEMPLATE_NOT_SET';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          return;
        }

        const options = this.getOptions();

        const res = await axios.post(`/member_renew/pagination/ids`, { options: options });

        if (res.status === 200) {
          this.selected_renew_ids = res.data;
          this.select_type = 'email';
          this.$refs['selectRenewsForBatchModalEmail'].show();

          return;
        }
      }
      catch (err) {
        console.error('create_email_batch_clicked', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_GET_RENEWS_FOR_EMAIL_BATCH'));

    },

    async create_sms_batch_clicked() {

      try {

        const renew_settings = await this.load_renew_settings_data();

        if (!renew_settings.send_sms) {
          this.error_popup_message = 'ERR_SMS_RENEW_NOT_ACTIVE';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          return;
        }

        if (!renew_settings.sms_template_id) {
          this.error_popup_message = 'ERR_SMS_RENEW_TEMPLATE_NOT_SET';

          this.$nextTick(()=>{ this.error_popup_message = null; });

          return;
        }


        const options = this.getOptions();

        const res = await axios.post(`/member_renew/pagination/ids`, { options: options });

        if (res.status === 200) {
          this.selected_renew_ids = res.data;
          this.select_type = 'sms';
          this.$refs['selectRenewsForBatchModalSMS'].show();

          return;
        }
      }
      catch (err) {
        console.error('create_email_batch_clicked', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_GET_RENEWS_FOR_SMS_BATCH'));

    },

    deleteRenewals() {
      if (this.selected.length === 0) {
        this.toastr('warning', this.$t('COMMON.ERROR'),  this.$t('PAGES.RENEWS.SELECT_AT_LEAST_ONE'));
        return;
      }

      axios
        .delete('/member_renew/multi', {
          data: { member_renew_ids: this.selected.map(item => item.id) }
        })
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.RENEWS.DELETED'));
            this.$refs['renewTable'].refresh();
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_DELETE'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_DELETE'));
        });
    },
  },
  data() {
    return {
      error_popup_message: null,
      has_errors: false,
      member_id: null,
      orderBy: {},
      totalRows: 0,
      select_type: 'email',
      selected_renew_ids: [],
      enableInvoiceDialog: true,
      isAllInvoices: false,
      markPrinted: false,
      filters: {
        text: '',
        status: 'all',
        payment_status: 'all',
        email_option: 'all',
        has_email: null,
        has_phone: null,
        created_from: null,
        created_to: null
      },
      per_page: 100,
      currentPage: 1,
      renewals: [],
      selected: [],
      toDelete: null,
      is_table_loading: false,
      statuses: [
        { label: this.$t('PAGES.RENEWS.SCHEDULED'), value: 'SCHEDULED' },
        { label: this.$t('PAGES.RENEWS.ACTIVE'), value: 'ACTIVE' },
        { label: this.$t('PAGES.RENEWS.DELETED'), value: 'DELETED' },
        { label: this.$t('PAGES.RENEWS.DONE'), value: 'DONE' },
        { label: this.$t('PAGES.RENEWS.AWAITING_PAYMENT'), value: 'AWAITING_PAYMENT' },
        { label: this.$t('PAGES.RENEWS.STARTED_LINK'), value: 'STARTED_LINK' },
        { label: this.$t('PAGES.RENEWS.INVOICED'), value: 'INVOICED' },
        { label: this.$t('PAGES.RENEWS.TIME_OUT'), value: 'TIME_OUT' }
      ],
      emailOptionOptions: [
        { text: this.$t('COMMON.ALL'), value: 'all' },
        { text: this.$t('PAGES.RENEWS.HAS_EMAIL'), value: 'EXISTS' },
        { text: this.$t('PAGES.RENEWS.MISSING_EMAIL'), value: 'NOT_EXISTS' }
      ],
      paymentStatuses: [
        { label: this.$t('INVOICE.PAID'), value: 'PAID' },
        { label: this.$t('INVOICE.WAIT'), value: 'WAIT' },
        { label: this.$t('INVOICE.LATE'), value: 'LATE' }
      ],
      createRenewals: {},
      originalRenewals: {},
      userFilteredRenews: [],
      headers: [
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          sortable: true,
          formatter: (_, __, item) => {
            return `${dayjs(item.created_at).format('YYYY-MM-DD')}`;
          },
          sortByFormatted: true,
          filterByFormatted: true,
          thClass: 'w-90px',
          tdClass: 'w-90px'
        },
        {
          key: 'name',
          label: this.$t('COMMON.NAME'),
          sortable: true,
          formatter: (_, __, item) => {
            let name = `${item.member.firstname} ${item.member.lastname}`;

            if (name.length > 20) {
              name = name.substring(0,20) + '...';
            }

            return name;
          },
          sortable: false,
          thClass: 'w-165px',
          tdClass: 'w-165px'
        },
        {
          key: 'member.email',
          label: this.$t('MEMBER.EMAIL'),
          thClass: 'w-165px',
          tdClass: 'w-165px',
          sortable: false,

        },
        {
          key: 'status',
          label: this.$t('COMMON.STATUS'),
          formatter: (_, __, item) => {
            return this.getStatus(item.status);
          },
          sortByFormatted: true,
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'payment_status',
          label: this.$t('COMMON.PAYMENT_STATUS'),
          formatter: (_, __, item) => {
            return this.getPaymentStatus(item.payment_status);
          },
          sortByFormatted: true,
          filterByFormatted: true,
          sortable: true
        },
        {
          key: 'email_sent',
          label: '# ' + this.$t('MEMBER.EMAIL'),
          formatter: (_, __, item) => {
            return this.getCount('EMAIL', item);
          },
          sortable: false
        },
        {
          key: 'sms_sent',
          label: '# SMS',
          formatter: (_, __, item) => {
            return this.getCount('SMS', item);
          },
          sortable: false
        },
        {
          key: 'show_details',
          label: ''
        }
      ],
      list: []
    };
  }
};
</script>


<style lang="css" scoped>
.error-text {
  font-style: bold;
  color: #c10000;
}



</style>
